// Container Sizing
$context-menu-min-width: 13em !default;
$context-menu-max-width: $context-menu-min-width * 2 !default;
$context-menu-container-padding: .25em 0 !default;
$context-menu-container-margin: .3em !default;
$context-menu-border-radius: .2em !default;

// Container Font
$context-menu-font-family: inherit !default;
$context-menu-font-size: inherit !default;

// Container Color
$context-menu-background-color: #FFF !default;
$context-menu-border-width: 1px !default;
$context-menu-border-style: solid !default;
$context-menu-border-color: #bebebe !default;
$context-menu-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5) !default;

// Item Sizing
$context-menu-padding-vertical: .2em !default;
$context-menu-padding-horizontal: 2em !default;
// Item Default
$context-menu-item-padding: $context-menu-padding-vertical $context-menu-padding-horizontal !default;
$context-menu-text-color: #2F2F2F !default;
$context-menu-item-color: $context-menu-background-color !default;

// Item Hover
$context-menu-item-color-hover: #2980B9 !default;
$context-menu-text-color-hover: #FFF !default;

// Item Disabled
$context-menu-item-color-disabled: $context-menu-background-color !default;
$context-menu-text-color-disabled: lighten($context-menu-text-color, 55) !default;

// Submenu
$context-menu-submenu-arrow-color: $context-menu-text-color !default;

// Separator
$context-menu-separator-height: 1px !default;
$context-menu-separator-style: solid !default;
$context-menu-separator-color: darken($context-menu-background-color, 10) !default;
$context-menu-separator-margin: .35em 0 !default;

// Icons
$context-menu-icon-font-path: 'font/' !default;
$context-menu-icon-font-name: 'context-menu-icons' !default;
$context-menu-icon-size: 1em !default;
$context-menu-icon-color: #2980B9;
$context-menu-icon-color-hover: $context-menu-text-color-hover;

@keyframes cm-spin {
  0% {
    -webkit-transform: translateY(-50%)  rotate(0deg);
    transform: translateY(-50%) rotate(0deg)
  }
  100% {
    -webkit-transform: translateY(-50%) rotate(359deg);
    transform: translateY(-50%) rotate(359deg)
  }
}
