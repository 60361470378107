/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.6.2
 *
*/

// Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en");

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


// Variables, Mixins
@import "import/variables";
@import "import/mixins";

// INSPINIA Theme Elements
@import "import/typography";
@import "import/navigation";
@import "import/top_navigation";
@import "import/buttons";
@import "import/badgets_labels";
@import "import/elements";
@import "import/sidebar";
@import "import/base";
@import "import/pages";
@import "import/chat";
@import "import/metismenu";
@import "import/spinners";

// Landing page styles
@import "import/landing";

// RTL Support
@import "import/rtl";

// For demo only - config box style
@import "import/theme-config";

// INSPINIA Skins
@import "import/skins";
@import "import/md-skin";

// Media query style
@import "import/media";

// Custom style
// Your custom style to override base style
@import "import/custom";

/* Social network */
$text-color: #1e1a17;
$primary-color: #222222;
$hover-color: #000011;
$hover-bg-color: #ddd;

@for $i from 1 through 5 {
  .margin-top-#{$i} {
    margin-top: #{$i}em !important;
  }
  .margin-bottom-#{$i} {
    margin-bottom: #{$i}em !important;
  }
}

body#oxxi {
  min-width: 480px;
  font-size: 14px;
  height:100%;
  padding-bottom: 70px;
  color: $text-color;
  /*background: url('../images/background.jpg') top center;
  background-size: cover;*/
  background:
          url('../images/background1.jpg') top center no-repeat,
          url('../images/background2.jpg') top center;
  background-size: 100% , 100%;
  /* jquery-contextmenu */
  .context-menu-icon.context-menu-icon--fa {
    padding: 7px 30px;
    &::before {
      color: $primary-color;
    }
  }
  #app {
    min-height: 100%;
  }
  h1 {
    font-size: 16px;
  }
  h2 {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: bold;
    .fa {
      font-size: 20px;
      display: block;
      float: left;
      margin-top: -1px;
    }
  }
  h3 {
    font-size: 14px;
  }
  .badge {
    color: #fff;
    padding-left: 5px;
    background-color: #ff0000;
    .fa {
      margin: 0;
    }
  }
  a.black {
    display: block;
    margin-bottom: 5px;
    color: #000;
    &:hover {
      color: #000;
    }
  }
  .margin-bottom-1 {
    margin-bottom: 1em;
  }
  .panel-default {
    background: none;
    border: none;
    .panel-heading {
      background-color: $primary-color;
      color: #fff;
    }
    .panel-body {
      padding: 0;
    }
  }
  .thumbnail {
    background-color: transparent;
    border: none;
  }
  .btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    color: #fff;
  }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary.active:hover, .btn-primary.active:focus {
    background-color: $hover-color;
    border-color: $hover-color;
    color: #fff;
  }
  .btn-link:hover, .btn-link:focus, .btn-link:active, .btn-link.active, .open .dropdown-toggle.btn-link {
    color: $hover-color;
  }
  .form-control:focus, .single-line:focus {
    border-color: $primary-color !important;
  }
  .pace {
    .pace-progress {
      background: none;
    }
  }
  .breadcrumb {
    background: none;
  }
  .navbar {
    color: #9a9a9a;
    background: $primary-color;
    a {
      color: #9a9a9a !important;
      &:hover,
      &:active {
        background-color: $hover-color !important;
      }
    }
    .navbar-collapse {
      display: block !important;
    }
    .buttons-top {
      padding-left: 0;
      .btn {
        margin-top: 8px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        color: $primary-color !important;
        background-color: #fff;
        &:hover {
          background-color: $hover-bg-color !important;
        }
      }
    }
    #topsearch {
      padding-top: 8px;
      li {
        width: 100%;
        .input-group-addon {
          border: 1px solid #e5e6e7;
          border-right: 0;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
        input[type=text] {
          margin-left: -12px;
          border-left: 0;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          color: $primary-color;
          &:focus {
            border-color: #e5e6e7 !important;
          }
        }
        .btn-primary {
          border: 1px solid $hover-color;
        }
        #search-autocomplete {
          position: absolute;
          width: 85%;
          margin-left: 32px;
          ul {
            list-style: none;
            color: #000;
            padding: 10px 0;
            border: 1px solid #aaa;
            background-color: #fff;
            li {
              padding: 0;
              &.selected {
                a {
                  color: #fff !important;
                  background-color: $primary-color !important;;
                }
              }
              a {
                display: block;
                padding: 5px;
                color: $primary-color !important;
                &:hover {
                  color: #fff !important;
                  background-color: $primary-color !important;
                }
              }
            }
          }
        }
        .spinner {
          position: absolute;
          top: 8px;
          right: 50px;
          width: 27px;
          height: 20px;
          text-align: center;
          font-size: 10px;
          z-index: 100;
          & > div {
            background-color: $primary-color;
            height: 100%;
            width: 3px;
            display: inline-block;
            -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
            animation: sk-stretchdelay 1.2s infinite ease-in-out;
          }
          .rect2 {
            -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
          }
          .rect3 {
            -webkit-animation-delay: -1.0s;
            animation-delay: -1.0s;
          }
          .rect4 {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
          }
          .rect5 {
            -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
          }
        }
        @-webkit-keyframes sk-stretchdelay {
          0%, 40%, 100% {
            -webkit-transform: scaleY(0.4)
          }
          20% {
            -webkit-transform: scaleY(1.0)
          }
        }

        @keyframes sk-stretchdelay {
          0%, 40%, 100% {
            transform: scaleY(0.4);
            -webkit-transform: scaleY(0.4);
          }
          20% {
            transform: scaleY(1.0);
            -webkit-transform: scaleY(1.0);
          }
        }
      }
    }
    .navbar-brand {
      width: 75px;
      img {
        height: 34px;
        margin-top: -7px;
      }
    }
    .dropdown-menu {
      background-color: $primary-color;
    }
    .open {
      > a {
        background-color: $hover-color !important;
      }
    }
  }
  .time-line {
    img {
      &.left {
        margin-top: 20px;
        margin-right: 10px;
      }
      &.right {
        margin-left: 10px;
      }
      float: left;
      width: 60px;
      margin-bottom: 10px;
    }
    .date {
      font-size: 12px;
      color: #888;
    }
    .thumbnail {
      min-height: 75px;
      background-color: #fff;
      border-radius: 20px;
      -moz-border-radius: 20px;
      -webkit-border-radius: 20px;
      .caption {
        padding: 0 9px;
      }
    }
    .contact-box {
      padding: 15px;
    }
  }
  .time-line-resp {
    img {
      width: 100%;
    }
  }
  .activities {
    a {
      color: #000;
    }
  }

  #login {
    margin-top: 10%;
    .panel-body {
      margin-top: -1px;
      padding: 40px 40px 30px 40px;
      border: 1px solid #222;
    }
  }

  #home,
  #profile,
  #network,
  #settings,
  #messenger,
  #search,
  #searchby,
  #search-country,
  #subscriptions {
    .network {
      padding: 0;
      margin: 0 -5px;
      .network-item {
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 10px;
        span {
          display: none;
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 2px 8px;
          font-size: .75em;
          line-height: .95em;
          overflow: hidden;
          color: #fff;
        }
      }
    }
    .profile-photo {
      width: 100%;
    }
  }

  #home {
    #vertical-timeline {
      width: 96%;
      margin-top: 0;
      &:before {
        background: #e7eaec;
      }
      .vertical-timeline-block {
        margin: 0;
        .vertical-timeline-content {
          &:after, &:before {
            top: 24px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }
          &:after {
            border-color: rgba(255, 255, 255, 0);
            border-width: 10px;
            margin-top: -10px;
          }
          &:before {
            border-color: rgba(231, 234, 236, 0);
            border-width: 11px;
            margin-top: -11px;
          }
          &.left {
            &:after, &:before {
              left: 100%;
            }
            &:after {
              border-left-color: #ffffff;
            }
            &:before {
              border-left-color: #e7eaec;
            }
          }
          &.right {
            &:after, &:before {
              right: 100%;
            }
            &:after {
              border-right-color: #ffffff;
            }
            &:before {
              border-right-color: #e7eaec;
            }
          }
          .vertical-date {
            top: 13px;
          }
        }
      }
    }
    .well {
      background: #ccc;
      border-color: #ccc;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      .btn-default {
        background: #f3f4ee;
      }
    }
    .new {
      i {
        font-size: 12px;
        color: #777;
      }
      h3, p {
        margin: 7px 0;
        line-height: 16px;
      }
    }
    .searches {
      h2 {
        margin-bottom: 0;
        font-size: 18px;
      }
    }
    .tags {
      margin: 0 30px;
      padding: 15px;
      background-color: #f7f6f1;
    }
  }

  #profile {
    h1 {
      font-size: 24px;
      font-weight: 400;
      margin-top: 0;
    }
    .profile-photo {
      width: 100%;
    }
    .btn {
      margin-top: -8px;
      &.btn-primary,
      &.btn-danger {
        margin-top: 0;
      }
    }
    .text-edit {
      cursor: pointer;
      &.hover {
        background-color: orange;
      }
    }
    #corrections {
      .btn {
        padding: 3px 6px;
      }
    }
  }

  #messenger {
    h2 {
      padding: 5px 0 0 15px;
    }
    #network-box {
      div {
        margin-bottom: 10px;
        img {
          width: 50px;
          float: left;
          margin-right: 10px;
        }
      }
    }
    .mailbox {
      display: block;
    }
    .caption {
      position: relative;
      .trash {
        display: none;
        position: absolute;
        bottom: 2px;
        right: 10px;
        color: #ff0000;
      }
    }
    #messages-dock {
      margin-bottom: 80px;
    }
    #message-compose {
      display: none;
      .fa-comment.left {
        float: left;
        font-size: 50px;
        margin-right: 10px;
      }
      .thumbnail {
        margin-bottom: 5px;
        background-color: #ccc;
        textarea {
          resize: none;
          color: #fff;
          background-color: transparent;
          border: none;
          &::-webkit-input-placeholder {
            color: #eee;
          }
          &::-moz-placeholder {
            color: #eee;
          }
          &:-ms-input-placeholder {
            color: #eee;
          }
          &:-moz-placeholder {
            color: #eee;
          }
        }
      }
      #message-send {
        margin-left: 75px;
        text-align: right;
        .btn-default {
          background-color: #aaa;
          border-radius: 10px;
          -moz-border-radius: 10px;
          -webkit-border-radius: 10px;
        }
      }
    }
  }

  #network {
    h3 {
      margin: 0 0 15px 0;
      padding: 0 15px;
    }
    .network-item {
      height: 17em;
      a {
        position: relative;
      }
      .accept {
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
      }
    }
    .caption {
      padding: 9px;
    }
  }

  #search,
  #search-country {
    .time-line {
      #search-load {
        font-size: 12px;
        .flag-icon {
          position: absolute;
          bottom: 10px;
          left: 15px;
        }
      }
      form {
        input {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
        .input-group-addon {
          padding: 0;
          background-color: inherit;
          button {
            padding: 9px 12px;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            border: 0;
          }
        }
        .clear-filter {
          position: absolute;
          top: 7px;
          right: 65px;
          z-index: 100;
        }
      }
      img.left {
        margin-top: 0;
      }
      h3 {
        margin-top: 10px;
      }
      a {
        color: #000;
      }
    }
  }

  #home,
  #searchby {
    .tags {
      text-align: center;
      .btn {
        margin: 0 .5em 1em 0;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        color: $primary-color;
        background-color: transparent;
        border-color: $primary-color;
        &:hover {
          background-color: $hover-bg-color !important;
        }
      }
      .badge {
        background-color: #222222;
      }
    }
  }

  #search-country {
    ul {
      padding-left: 0;
      list-style: none;
      li {
        padding: 5px 0;
      }
    }
  }

  #footer {
    margin-top: 20px;
    height: 50px;
    color: #9a9a9a;
    background: #222222;
    .container {
      padding-top: 15px;
      text-align: center;
    }
  }

  .modal-content {
    background: url('../images/background.jpg') top center;
    textarea {
      background-color: rgba(255,255,255,0.7);
    }
  }

  #modal-croppic {
    .modal-dialog {
      overflow: hidden;
    }
    #profile-image {
      margin: 0 auto;
      width: 302px;
      height: 452px;
      position: relative;
      border: 1px solid #ccc;
      .btn-danger {
        padding: 3px 8px;
        border-radius: 0;
        margin: -1px;
      }
    }
  }

  .profile-image_imgUploadForm {
    height: 0;
  }

  #banner-top {
    img {
      width: 100%;
      height: auto;
    }
  }
  #banner-right {
    img {
      width: 100%;
      height: auto;
    }
  }

  @media only screen and (max-width : 767px) {
    .navbar-nav {
      margin: 0 0 0 15px;
      padding-top: 0;
      .dropdown-toggle {
        padding-top: 15px;
      }
    }
    #network {
      .network-item {
        height: 18em;
      }
    }
  }
}

/* Edit biography without account */
.ibox-title {
  .btn-right {
    float: right;
    margin-top: -9px;
  }
}
.ibox-content {
  font-size: 1.2em;
  line-height: 1.45em;
  .text-edit {
    cursor: pointer;
    &.hover {
      background-color: orange;
    }
  }
}
.modal-header {
  padding: 15px 30px;
}
.modal-footer {
  position: relative;
  .modal-info {
    position: absolute;
    top: 25px;
    left: 30px;
  }
}
#modal-edit {
  .modal-body {
    textarea {
      width: 100%;
      height: 100%;
      border: 1px solid #ddd;
    }
  }
}

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }
  body { overflow: visible !important; }

  #page-wrapper {
    margin: 0;
  }
}
